<template>
  <div class="navkontaktbtns">
    <div class="navkontaktbtns__item">
      <a :href="getPhone" class="navkontaktbtns__a">
        <div class="material-icons-round" translate="no">
          phone
        </div>
        <div class="navkontaktbtns__text"> {{kontakt.phone}}</div>
      </a>
    </div>
    <div class="navkontaktbtns__item">
      <a :href="getMail" class="navkontaktbtns__a">
        <div class="material-icons-round" translate="no">
          email
        </div>
        <div class="navkontaktbtns__text"> {{kontakt.email}}</div>
        </a
      >
    </div>
    <div class="navkontaktbtns__item">
      <a :href="kontakt.googlemaps" target="_blank" class="navkontaktbtns__a">
        <div class="material-icons-round" translate="no">
          place
        </div>
        <div class="navkontaktbtns__text"> {{kontakt.address.street}}  {{kontakt.address.plz}} {{kontakt.address.location}}</div>
      </a>
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "@/utilities/siteinformation/contactInformation";
export default {
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    const { getPath } = getPathFunction();

    return { kontakt, getMail, getPhone, getPath };
  },
};
</script>
