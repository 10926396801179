<template>
  <div id="ocontroller">
    <div id="ocontroller__wrapper">
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {  },
  setup() {
    const store = useStore();

    function openAnimation() {
      let ease1 = "power4.out";

      gsap.from("#ocontroller", {
        force3D: true,
        duration: 0.4,
        ease: ease1,
        y: -200,
        opacity: 0,
        rotation: 0.02,
      });
    }
    onMounted(() => {
      openAnimation();
    });
    return { store };
  },
};
</script>

<style lang="scss">
#ocontroller {
  position: relative;
  max-width: $size-app-max-width;
  width: 100%;
  margin: 0 auto;
}
#ocontroller__wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 4000;

  left: 0;
  right: 0;

  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(94.93deg, #ffffff 36.41%, rgba(255, 255, 255, 0) 53.97%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;

  top: 0;

  @media screen and (min-width: $medium) {
    width: 350px;
    top: calc(#{$navi-height-desktop} + 16px);
    height: calc(100vh - #{$navi-height-desktop} - 16px);
    max-height: 660px;
    overflow-y: scroll;
    z-index: 4000;

    right: 24px;
    left: auto;
    overflow: hidden;
  }

  @media screen and (min-width: $xlarge) {
  }
}
</style>
