<template>
  <div
    class="navbtn"
    :class="{ isActive: isSpecial || onMouseEnter }"
    @click="onClick"
    @mouseenter="onMouseEnter = true"
    @mouseleave="onMouseEnter = false"
  >
    <div class="navbtn__text">
      <slot></slot>
      <div class="navbtn__marker"></div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  props: { dataname: String },
  setup(props) {
    const store = useStore();
    const onMouseEnter = ref(false);
    const publicPath = process.env.BASE_URL;

    const isBtn = ref(props.dataname);

    function onClick() {
      store.commit("setMenuState", false);
    }

    const isSpecial = computed(() => {
      return store.state.isActiveRoute == publicPath + isBtn.value;
    });

    return { onMouseEnter, isSpecial, store, isBtn, onClick };
  },
};
</script>

