<template>
  <div class="navdesktop" :class="{ isblack: store.state.isMenuOpen }">
    <div class="navdesktop__wrapper">
      <div class="navdesktop__left">
          <router-link :to="prefixPath('')" class="navdesktop__logo" @click="onClick()">
            <TheLogoSmall :class="{ isblack: store.state.isMenuOpen }" />
          <TheLogoIMG :path="'assets/img/logo/1a-autoservice-logo.png'"/>
          </router-link>
      </div>
      <div class="navdesktop__middle">
        <NavKontaktBtns :class="{ isblack: store.state.isMenuOpen }" />
      </div>
      <div class="navdesktop__right">
        <NavMenuBtn :class="{ isblack: store.state.isMenuOpen }" />
      </div>
    </div>
  </div>
</template>

<script>
// import NavBtn from "./NavBtn";
import TheLogoSmall from "@/components/ui/logo/TheLogoSmall.vue";
import TheLogoIMG from "@/components/ui/logo/TheLogoIMG.vue";
import { computed } from "@vue/runtime-core";
import NavMenuBtn from "./NavMenuBtn";
import NavKontaktBtns from "./NavKontaktBtns.vue";

import { useStore } from "vuex";
export default {
  components: { NavMenuBtn, TheLogoSmall, NavKontaktBtns, TheLogoIMG },

  setup() {
    const store = useStore();
    const publicPath = process.env.BASE_URL;

    const isPeople = computed(() => {
      return store.state.isPeopleOpen;
    });
    const isHelp = computed(() => {
      return store.state.isHelpDeskOpen;
    });
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    const messageText = computed(() => {
      if (store.state.isMenuOpen) {
        return "menü";
      } else {
        return store.state.isActiveRouteName;
      }
    });
    //  const getPath = function(path) {
    // let newP = path.split(".jpg");
    // if (newP.length >= 2) {
    //   return publicPath + path;
    // }}

    function onClick() {
      store.commit("setMenuState", false);
    }
    
    function prefixPath(el) {
      return publicPath + el;
    }

    function toggleElement(e, el) {
      console.log("e", e, el);
    }
    return {
      isPeople,
      isHelp,
      isMenuOpen,
      toggleElement,
      prefixPath,
      store,
      messageText,
      onClick
    };
  },
};
</script>
