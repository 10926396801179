const service = [
  {
    name: "Reparaturen aller Arten",
    text:"Lorem"
  },
  {
    name: "Reifenservice mit Einlagerung",
    text:"Lorem"
  },
  {
    name: "TÜV",
    text:"Lorem"
  },
  {
    name: "Inspektion nach Herstellervorgaben",
    text:"Lorem"
  },
  {
    name: "Diagnosetechnik",
    text:"Lorem"
  },
  {
    name: "Unfallinstandsetzung",
    text:"Lorem"
  },
  {
    name: "Unfallabwicklung",
    text:"Lorem"
  },
  {
    name: "Lackierarbeiten",
    text:"Lorem"
  },
  {
    name: "Karosseriearbeiten",
    text:"Lorem"
  },
  {
    name: "Smart Repair",
    text:"Lorem"
  },
  {
    name: "Glasservice",
    text:"Lorem"
  },
  {
    name: "Ersatzteilverkauf",
    text:"Lorem"
  },
  {
    name: "KFZ - Ankauf / -Handel",
    text:"Lorem"
  },
  {
    name: "Werkstattersatzwagen",
    text:"Lorem"
  },
];
export default service;
