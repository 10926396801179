<template>
  <div class="thelogo__small">
    <svg class="thelogo__small--svg" viewBox="0 0 133.01 137.13" xmlns="http://www.w3.org/2000/svg">
        <path
        class="small_path"
          d="m102.35 47.62c24.28.24 46.57 5.13 65.55 9.71l1 .24.06 1a155.1 155.1 0 0 1 -3.18 42.9 120.63 120.63 0 0 1 -13.57 34.58 124.85 124.85 0 0 1 -21.79 27.25 162.31 162.31 0 0 1 -27.81 21l-.72.44-.72-.44a158 158 0 0 1 -27.77-21.38 122.05 122.05 0 0 1 -21.28-27.55 121.61 121.61 0 0 1 -12.87-34.86 162.06 162.06 0 0 1 -2.6-43.2l.07-1 1-.25a260.37 260.37 0 0 1 56.8-8.36c2.59-.09 5.24-.12 7.88-.09z"
    
          fill-rule="evenodd"
          transform="translate(-36.24 -47.61)"
        />
        <path 
        class="small_bg"
          d="m94.51 49.09a261.42 261.42 0 0 0 -56.51 8.31c-4.14 63.2 23.64 101 63.86 125.72 39.84-24.1 69.28-61.74 65.69-124.45-21-5.07-45.94-10.49-73.04-9.58z"
          fill-rule="evenodd"
          transform="translate(-36.24 -47.61)"
        />
        <g class="small_path">
          <path d="m29.11 90.22-1.07-1.09 75.25-73.79 1.07 1.09z" />
          <path d="m102.55 90.93-73.79-75.24 1.09-1.07 73.79 75.24z" />
          <path
            d="m68.79 101.17-.8 1.46v4.93h-2.52l.16-16.07h2.52l-.07 7 3.33-6.92h2.52l-3.58 7.13 3.42 8.94h-2.59z"
            transform="translate(-36.24 -47.61)"
          />
          <path
            d="m102.5 70.64h3.26v2.29h-3.26l-.06 6.64h-2.53l.09-16.1 6.68.07v2.29h-4.16z"
            transform="translate(-36.24 -47.61)"
          />
          <path
            d="m139 92.19v2.25l-4.91 11.47h4.8v2.3l-7.39-.08v-2.25l4.91-11.47h-4.57v-2.3z"
            transform="translate(-36.24 -47.61)"
          />
          <path
            d="m127.27 132.49v-.35a6.77 6.77 0 0 0 -4.11-3.49h-1c-1.45.47-3 2.09-5.1 2.6s-5.23.29-8.29.26l-5.14-.05s2 0 0-.06-8.92 0-12.57-.25c-1.17-.06-2.24.1-3.22 0-2-.26-3-2.32-4.81-2.69-1.63-.34-3.74.18-4.62 1.45 1.27.58 3.35.37 4.25 1.31a4.52 4.52 0 0 1 -.73 3.68c-1.77.4-3.35-1-4.94-.74v.34a6.8 6.8 0 0 0 4.12 3.5h1a9.71 9.71 0 0 0 2.06-1.13h.07a9.92 9.92 0 0 1 3-1.43c2.18-.54 5.24-.3 8.3-.27l5.08.05.06.07c1.92 0 8.92.05 12.56.24 1.18.07 2.25-.09 3.23 0 2 .25 3 2.32 4.8 2.69 1.63.33 3.75-.19 4.63-1.45-1.27-.58-3.35-.37-4.25-1.31a4.48 4.48 0 0 1 .73-3.68c1.77-.4 3.35 1 4.94.74"
            transform="translate(-36.24 -47.61)"
          />
          
        </g>
      </svg>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  setup() {
    const { getPath } = getPathFunction();

    return { getPath };
  },
};
</script>

<style lang="scss" scoped>
.thelogo__small{
	height: 100%;
	width:auto;

}
.thelogo_png{
	height: 100%;
	widows: auto;
}

.thelogo__small--svg{
 height: 100%;
}

.small_path{

  fill:black;

  .isblack & {
    fill:white;
  }
}
.small_bg{
  fill:white;
  .isblack & {
    fill:black;
  }
}
</style>
