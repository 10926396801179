<template>
  <div v-if="showHelperBox" class="helperbox" >
    <NavOverlay v-if="store.state.isMenuOpen"/>
    <div v-if="isControllOpen" class="overlay__controller">
      <OverlayController />
    </div>
  </div>
</template>

<script>

import NavOverlay from "@/components/common/navigation/NavOverlay.vue";
import OverlayController from "@/components/common/overlay/OverlayController";


import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default {
  components: {
    NavOverlay,
    OverlayController,
  },
  setup() {
    const store = useStore();
    const isControllOpen = computed(() => {
      return (
        store.state.isHelpDeskOpen ||
        store.state.isPeopleOpen
      );
    });
    const showHelperBox = computed(() => {
      return (
        store.state.isMenuOpen ||
        store.state.isHelpDeskOpen ||
        store.state.isPeopleOpen
      );
    });

    return { store, isControllOpen, showHelperBox};
  },
};
</script>

<style lang="scss">

</style>
