<template>
  <div class="thelogo_IMG">
    <img
      :src="getPath(path)"
      alt=""
      class="thelogo_IMG"
    />
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  props:{path:String},
  setup() {
    const { getPath } = getPathFunction();

    return { getPath };
  },
};
</script>

<style lang="scss">
.thelogo_IMG {

  padding-left: 10px;
  display: inline-block;
  height: 100%;
  width:auto;
}
.thelogo_IMG{
  height: 100%;
  width:auto;
}
.thelogo__color {
  fill: white;
}

.thelogo {
  &.isblack {
    .thelogo__color {
      fill: black;
    }
  }
}
</style>
