<template>
  <div class="menu">
    <div
      ref="middleblock"
      class="menu__wrapper"
      :class="{ scrollable: needScroll }"
    >
      <div ref="menu__topblock" class="menu__topblock"></div>
      <div ref="menu__middleblock" class="menu__middleblock">
        <div class="menu__nabuttoncontent" >

          <div class="menu__hl">
            <router-link :to="prefixPath('')" class="navmobile__routes" @click="onClick()">
              Home
          </router-link>
        
          </div>
          <div class="menu__hl">
            Leistungen:
          </div>
          <div
            v-for="(leistung, index) in leistungen"
            :key="index"
            class="menu__btn"

            @click="onClick()"
          >
            <NavBtn> {{ leistung.name }}</NavBtn>
          </div>
        </div>
      </div>

      <!--  <router-link :to="prefixPath('')" class="menu__routes">
            <NavBtn :dataname="''">home</NavBtn>
          </router-link> -->


      <div class="menu__bottomblock" ref="menu__bottomblock" @click="onClick()">
        <TheFooter :nologo="true" :color="'white'" class="menu__footer" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBtn from "./NavBtn.vue";

import service from "@/utilities/siteinformation/service.js";
import { computed, onBeforeUnmount } from "@vue/runtime-core";
import { onMounted, ref, watchEffect } from "@vue/runtime-core";
import gsap from "gsap";

import { useStore } from "vuex";
import TheFooter from "../footer/TheFooter.vue";
export default {
  components: {
    NavBtn,
    TheFooter,
  },

  setup() {
    const store = useStore();

    const leistungen = service;
    const publicPath = process.env.BASE_URL;

    const needScroll = ref(false);

    const getState = computed(() => {
      if (
        store.state.isScreenState == "medium" ||
        store.state.isScreenState == "small"
      ) {
        return true;
      }
    });

    function prefixPath(el) {
      return publicPath + el;
    }

    const middleblock = ref(null);
    const menu__topblock = ref(null);
    const menu__middleblock = ref(null);
    const menu__bottomblock = ref(null);

    function killTwenns() {
      gsap.killTweensOf(middleblock.vaule);
    }
    function openAnimation() {
      killTwenns();

      gsap.from(middleblock.value, {
        duration: 0.5,
        y: "-100%",
        rotation: 0,
        ease: "power2.inOut",
      });
    }
    // function closeAnimation() {
    //   killTwenns();
    //   gsap.to(middleblock.value, {
    //     duration: 0.5,
    //     y: "-100%",
    //     rotation: 0.02,
    //     ease: "power2.inOut",
    //     onComplete: () => {},
    //   });
    // }

    function onClick() {
      store.commit("setMenuState", false);
    }
    function resetSize() {
      // console.log("resetSize", middleblock.value);
      // gsap.set(middleblock.value, { height: window.innerHeight });
      // if (
      //   middleblock.value.getBoundingClientRect().height <
      //   menu__topblock.value.getBoundingClientRect().height +
      //     menu__middleblock.value.getBoundingClientRect().height +
      //     menu__bottomblock.value.getBoundingClientRect().height
      // ) {
      //   console.log("to small");
      //   needScroll.value = true;
      // } else {
      //   console.log("to big");
      //   needScroll.value = false;
      // }
    }

    onMounted(() => {
      resetSize();
      window.addEventListener("resize", resetSize);
      openAnimation();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resetSize);
    });

    watchEffect(() => {
      if (store.state.isMenuOpen) {
        document.body.style.overflow = "hidden";
        // openAnimation();
      } else {
        document.body.style.overflow = "auto";
        // closeAnimation();
      }
    });

    return {
      prefixPath,
      getState,
      middleblock,
      menu__topblock,
      menu__middleblock,
      menu__bottomblock,
      needScroll,
      leistungen,
      onClick
    };
  },
};
</script>

<style lang="scss"></style>
