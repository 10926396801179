<template>
  <div
    id="navmenubtn"
    ref="navmenubtnIcon"
    :class="isMenuOpen ? 'open' : ''"
    class="no-touch-out"
    @click.prevent="toggelMenu"
  >
    <div class="navmenubtn__icon">
      <span id="s_1"></span>
      <span id="s_2"></span>
      <span id="s_3"></span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";

import gsap from "gsap";
export default {
  setup() {
    const store = useStore();
    const navmenubtnIcon = ref(null);
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    function toggelMenu() {
      if (isMenuOpen.value) {
        closeMenu();
      } else {
        openMenu();
      }
    }
    function closeMenu() {
      store.commit("setMenuState", false);
    }
    function openMenu() {
      store.commit("setMenuState", true);
    }
    onMounted(() => {
      gsap.to("#navmenubtn", { opacity: 1, duration: 1, delay: 0 });
    });
    return { isMenuOpen, closeMenu, openMenu, toggelMenu, navmenubtnIcon };
  },
};
</script>

<style lang="scss" scoped>

</style>
